.App {
  text-align: center;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

/* .inner {
  width: 950px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
} */

.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.outer h3 {
  font-weight: bold;
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

label{
  font-weight: 600;
}
.forgot-password,
.forgot-password{
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
  cursor: pointer;
}

.forgot-password a {
  color: #167bff;
}